import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/uguryildirim/git/eteration/ui-components/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props } from 'docz';
import Playground from 'components/Playground';
import Datepicker from "components/Datepicker";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "datepicker"
    }}>{`Datepicker`}</h1>

    <Playground __position={0} __code={'() => {\n  const [selectedDate, setSelectedDate] = React.useState(\'\')\n  return (\n    <>\n      <p>Selected Date: {selectedDate.toString() || new Date().toString()}</p>\n      <div style={{ width: \'200px\', padding: \'20px\', marginLeft: \'20px\' }}>\n        <Datepicker\n          locale=\"tr\"\n          label=\"Datepicker\"\n          onChange={e => setSelectedDate(e)}\n        />\n      </div>\n    </>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Datepicker,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [selectedDate, setSelectedDate] = React.useState('');
        return <>
        <p>Selected Date: {selectedDate.toString() || new Date().toString()}</p>
        <div style={{
            width: '200px',
            padding: '20px',
            marginLeft: '20px'
          }}>
          <Datepicker locale="tr" label="Datepicker" onChange={e => setSelectedDate(e)} mdxType="Datepicker" />
        </div>
      </>;
      }}
    </Playground>
    <Props of={Datepicker} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      